import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const ExamplesOfCosmeticsAndHealthcare = ({ location }) => {
  const title =
    "【コスメ・ヘルスケア向け】おすすめ販売促進キャンペーンアイデア・厳選事例10選"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【コスメ・ヘルスケア向け】おすすめ販売促進キャンペーンアイデア・厳選事例10選"
          pagedesc="SNSアカウントはあるが活用方法がわからない、商品を宣伝し売上に繋げたいなどお困りなことはありませんか。Twitterは「拡散力の高さ」が特徴のSNSです。今回はコスメ・ヘルスケアアカウント向けの販売促進キャンペーンをご紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/examples-of-cosmetics-and-healthcare.jpg"
          pagepath="/blog/examples-of-cosmetics-and-healthcare"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は9分で読むことができます
            </div>
            <h1>
              【コスメ・ヘルスケア向け】おすすめ販売促進キャンペーンアイデア・厳選事例10選
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年12月15日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="並べられているピンクのパッケージの化粧品"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/examples-of-cosmetics-and-healthcare.jpg"
                className="w-full"
              />
            </figure>
            <h2>Twitterの拡散力をバネに、消費者への認知を広めていこう</h2>
            <p>
              年齢や環境、体調、肌の色などは人それぞれ異なります。数多く存在するコスメ・ヘルスケア商品の中で自分に合ったもの選ぶのは消費者にとって大変な作業です。だからこそまずは「認知」されることが重要です。
            </p>
            <p>
              Twitterはユーザー数の多さと拡散力の高さが特徴なので、Twitter上でのキャンペーンは認知拡大に大きな効果を発揮します。しかし、Twitterキャンペーンにも様々な種類とそれぞれの利点があります。
            </p>
            <p>
              本記事ではコスメ・ヘルスケアを扱うアカウントで開催された販売促進キャンペーンの成功事例5選、さらにインスタントウィン厳選の事例5選と有効的にTwitterキャンペーンを開催するための方法をご紹介します。
            </p>
            <p>ぜひ本記事を参考に、販売促進キャンペーンにお役立てください。</p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    最新のコスメ・ヘルスケア市場の動向は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    【コスメ・ヘルスケア】参考になる！Twitterキャンペーン成功事例5選
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        1. LANCÔME（ランコム）公式
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        2. uno（ウーノ）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        3. HAP+R（ハプウアール）公式
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        4. Visée ヴィセ 公式
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        5. めぐりズム公式
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    【その場で当たる！インスタントウィン厳選】販促キャンペーン5選
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        1. ディアナチュラ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        2. ミノン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        3. watashi+ by shiseido
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        4. Laline〈ラリン〉公式
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        5. 花王 エスト【公式】
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id14" className="toc__link">
                    【コスメ・ヘルスケア向け】おすすめの販売促進キャンペーンアイデアとは
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    SNSをうまく活用してコスメ・ヘルスケアの売上向上へつなげよう
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">最新のコスメ・ヘルスケア市場の動向は？</h2>
              <p>
                コスメ市場は2019年から2021年にかけて新型コロナウイルスの影響を受け、緊急事態宣言やまん延防止により、外出の機会の減少・マスクの定着化が進み、メイク用品を中心に需要が減少しました。しかし2022年の春以降は「プチプラ」と呼ばれる低価格帯の高機能商品の需要が伸びつつあります。また、多様性を重視する価値観やSNSの流行などで美意識が高い男性が増加し、男性向け化粧品市場の拡大しています。
              </p>
              <p>
                一方、健康食品については新型コロナウイルスにより国民の健康意識が向上し、大きく市場が拡大する見込みです。また、双方共に対面や実店舗からECサイトへのシフトチェンジが加速しています。
              </p>
              <div className="blog__border-highlight">
                <p>
                  参考：
                  <br />
                  <a
                    href="https://gyokai-search.com/3-kesyo.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    化粧品業界の動向や現状、ランキング等を研究-業界動向サーチ
                  </a>
                  <br />
                  <a
                    href="https://www.yano.co.jp/press-release/show/press_id/2938#"
                    target="_blank"
                    rel="noreferrer"
                  >
                    健康食品市場に関する調査を実施（2022年） |
                    ニュース・トピックス |
                    市場調査とマーケティングの矢野経済研究所
                  </a>
                  <br />
                  <a
                    href="https://money-bu-jpx.com/news/article037852/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    拡大する男性向け化粧品市場　～産業ウォッチ（2）～ |
                    東証マネ部！
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id2">
                【コスメ・ヘルスケア】参考になる！Twitterキャンペーン成功事例5選
              </h2>
              <h3 id="id3">1. LANCÔME（ランコム）公式</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼プレゼントキャンペーン／
                  <br />
                  <br />
                  11/24(木)の20時より
                  <a href="https://twitter.com/hashtag/%E7%BE%8E%E9%A6%99?src=hash&amp;ref_src=twsrc%5Etfw">
                    #美香
                  </a>
                  さん &amp;
                  <a href="https://twitter.com/hashtag/%E5%B7%9D%E4%B8%8A%E6%A1%83%E5%AD%90?src=hash&amp;ref_src=twsrc%5Etfw">
                    #川上桃子
                  </a>
                  さんご出演インスタライブ開催決定！
                  <br />
                  ライブを記念し
                  <a href="https://twitter.com/hashtag/%E3%82%A2%E3%83%97%E3%82%BD%E3%83%AA%E3%83%A5?src=hash&amp;ref_src=twsrc%5Etfw">
                    #アプソリュ
                  </a>
                  ソフトクリームを1名さまへ
                  <a href="https://twitter.com/hashtag/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                    #プレゼント
                  </a>
                  <br />
                  <br />
                  ①この投稿をRT
                  <br />②
                  <a href="https://twitter.com/Lancome_JP?ref_src=twsrc%5Etfw">
                    @lancome_JP
                  </a>
                  をフォロー
                  <br />
                  11/30(水)〆
                  <br />
                  <br />
                  テーマは至福のご褒美美容♡
                  <a href="https://twitter.com/hashtag/%E3%83%A9%E3%83%B3%E3%82%B3%E3%83%A0?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ランコム
                  </a>
                  <a href="https://t.co/uiIeZm564r">
                    pic.twitter.com/uiIeZm564r
                  </a>
                </p>
                &mdash; LANCÔME（ランコム）公式 (@Lancome_JP)
                <a href="https://twitter.com/Lancome_JP/status/1595009199033188352?ref_src=twsrc%5Etfw">
                  November 22, 2022
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2022年11月22日〜11月30日</li>
                <li>
                  キャンペーンタイプ：フォロー＆リツイート
                  <p>
                    ・更に「ご自分が挑戦したい、至福のご褒美美容」について引用リツイートで当選確率アップ
                  </p>
                </li>
                <li>景品：アプソリュソフトクリーム</li>
                <li>当選者数：1名</li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/Lancome_JP"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LANCÔME Twitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・当選者のみDM通知</p>
                </li>
                <li>
                  概要
                  <p>
                    ・特別ゲスト出演のインスタライブ開催を記念し実施されました。また、インスタライブではライブ視聴特典として、出演者が選んだ2名様にも「アプソリュ
                    ソフトクリーム」がプレゼントされます。
                  </p>
                </li>
                <li>
                  効果
                  <p>・インスタライブの告知・集客、化粧品の宣伝ができる</p>
                  <p>・引用リツイートにより拡散力が向上する</p>
                </li>
              </ul>

              <h3 id="id4">2. uno（ウーノ）</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ✨
                  <a href="https://twitter.com/hashtag/uno%E3%81%A7%E3%81%A4%E3%81%8F%E3%82%8B%E5%A5%BD%E5%8D%B0%E8%B1%A1%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #unoでつくる好印象キャンペーン
                  </a>
                  ✨
                  <br />
                  <br />
                  【就活生必見】
                  <br />
                  11/27に『就活「好印象」セミナー』を配信🎦
                  <br />
                  <br />
                  配信を記念して「unoでつくる好印象パック」
                  を抽選で50名様にプレゼント中🎁
                  <br />
                  <br />
                  1.
                  <a href="https://twitter.com/uno_official?ref_src=twsrc%5Etfw">
                    @uno_official
                  </a>
                  をフォロー
                  <br />
                  2. この投稿をリツイート で応募完了！
                  <br />
                  <br />
                  詳しくはリンクをクリック👇
                  <a href="https://twitter.com/hashtag/%E3%82%A6%E3%83%BC%E3%83%8E?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ウーノ
                  </a>
                  <a href="https://twitter.com/hashtag/%E5%B0%B1%E6%B4%BB?src=hash&amp;ref_src=twsrc%5Etfw">
                    #就活
                  </a>
                  <a href="https://twitter.com/hashtag/24%E5%8D%92?src=hash&amp;ref_src=twsrc%5Etfw">
                    #24卒
                  </a>
                </p>
                &mdash; uno（ウーノ） (@uno_official)
                <a href="https://twitter.com/uno_official/status/1592094994810245120?ref_src=twsrc%5Etfw">
                  November 14, 2022
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2022年11月14日〜11月26日</li>
                <li>キャンペーンタイプ：フォロー＆リツイート</li>
                <li>景品：unoでつくる好印象パック（4点セット）</li>
                <li>当選者数：50名</li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/uno_official"
                      target="_blank"
                      rel="noreferrer"
                    >
                      uno Twitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・当選者のみDM通知</p>
                </li>
                <li>
                  概要
                  <p>
                    ・YouTubeにて『就活「好印象」セミナー』配信を記念し開催されました。また、エンカレッジ会員限定でセミナーの申込者先着300名様にunoの人気３商品のサンプルがプレゼントされます。
                  </p>
                </li>
                <li>
                  効果
                  <p>・セミナーの告知・集客ができる</p>
                  <p>・会員登録者数増加に繋がる</p>
                  <p>・市場が拡大する男性向け化粧品の宣伝ができる</p>
                </li>
              </ul>
              <h3 id="id5">3. HAP+R（ハップアール）公式</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <br />
                  ㊗いよいよ本日スタート✨
                  <a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%83%E3%83%97%E3%82%A2%E3%83%BC%E3%83%AB?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ハップアール
                  </a>
                  フェイススクラブ
                  <a href="https://twitter.com/hashtag/%E5%A5%BD%E3%81%8D%E3%81%8B%E5%AB%8C%E3%81%84%E3%81%8B?src=hash&amp;ref_src=twsrc%5Etfw">
                    #好きか嫌いか
                  </a>
                  キャンペーン📢
                  <br />＼
                  <a href="https://twitter.com/hashtag/%E4%BD%90%E9%87%8E%E5%8B%87%E6%96%97?src=hash&amp;ref_src=twsrc%5Etfw">
                    #佐野勇斗
                  </a>
                  さんの美文字入りQUOカードを購入者全員にプレゼント🎁
                  <br />
                  ※直筆をプリントしたものになります。
                  <br />
                  <br />
                  更にはWチャンスも‼
                  <br />
                  <br />
                  詳しくは下記より⬇
                  <br />
                  <br />
                  繋がりにくい場合は時間をおいてアクセスください🙇‍♂️
                </p>
                &mdash; HAP+R（ハップアール）公式 (@hapr_official)
                <a href="https://twitter.com/hapr_official/status/1589818742329946114?ref_src=twsrc%5Etfw">
                  November 8, 2022
                </a>
              </blockquote>
              <ul>
                <li>
                  実施期間：2022年11月8日〜12月7日（Wチャンス：2022年11月8日～12月31日）
                </li>
                <li>キャンペーンタイプ：商品購入</li>
                <li>
                  景品・当選者数：佐野勇斗さん美文字入りQUOカード300円分　期間中対象商品の購入者全員
                  <br />
                  　　　　　　　　WチャンスでQUOカード1万円分 10名
                </li>
                <li>
                  参加方法
                  <p>1. 画像URLよりキャンペーンページに遷移</p>
                  <p>2. 対象商品を購入</p>
                  <p>Wチャンスキャンペーン</p>
                  <p>
                    1.{" "}
                    <a
                      href="https://www.instagram.com/hapr_official/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ハップアール公式Instagramアカウント
                    </a>
                    をフォロー
                  </p>
                  <p>
                    2.
                    ハップアール『フェイススクラブ』を使用した感想・商品同封の「好き嫌いカード」・「#ハップアールスクラブ好き派」「#ハップアールスクラブ嫌い派」のどちらかをつけてInstagram
                    に投稿
                  </p>
                </li>
                <li>
                  結果通知
                  <p>・当選者のみDM通知</p>
                </li>
                <li>
                  概要
                  <p>
                    ・対象商品の購入者全員に限定QUOカードがプレゼントされるキャンペーンです。また、Wチャンスとして商品を使用した感想をカードと特定ハッシュタグをつけInstagramに投稿するとさらにQUOカードが当たります。
                  </p>
                </li>
                <li>
                  効果
                  <p>
                    ・有名人を起用し、限定景品を特典とすることでファン層への訴求や話題性を獲得
                  </p>
                  <p>
                    ・全員に景品がプレゼントされるため商品の購入促進に繋がる
                  </p>
                </li>
              </ul>
              <h3 id="id6">4. Visée ヴィセ 公式</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ドン・キホーテ 限定発売
                  <br />
                  プレストパウダー 当たる❣
                  <br />
                  ━━━━━━━━━━━💝💝💝
                  <br />
                  <br />
                  本日11/16発売✨
                  <br />
                  しっとりシルク肌を叶える「ヴィセ
                  <a href="https://twitter.com/hashtag/%E3%83%88%E3%83%BC%E3%83%B3%E3%82%A2%E3%83%83%E3%83%97%E3%83%97%E3%83%AC%E3%82%B9%E3%83%88%E3%83%91%E3%82%A6%E3%83%80%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #トーンアッププレストパウダー
                  </a>
                  」を、20名様に
                  <a href="https://twitter.com/hashtag/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                    #プレゼント
                  </a>
                  ❣
                  <br />
                  <br />
                  💘応募方法
                  <br />①
                  <a href="https://twitter.com/visee_official?ref_src=twsrc%5Etfw">
                    @visee_official
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRT
                  <br />
                  <br />
                  応募は11/23(水) 23:59まで♪
                </p>
                &mdash; Visée ヴィセ 公式 (@visee_official)
                <a href="https://twitter.com/visee_official/status/1592714072687972352?ref_src=twsrc%5Etfw">
                  November 16, 2022
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2022年11月16日〜11月23日</li>
                <li>キャンペーンタイプ：フォロー＆リツイート</li>
                <li>景品：トーンアッププレストパウダー</li>
                <li>当選者数：20名</li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/visee_official"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visée Twitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・当選者のみDM通知</p>
                </li>
                <li>
                  概要
                  <p>
                    ・ドン・キホーテ限定の新発売商品が当たるキャンペーンです。
                  </p>
                </li>
                <li>
                  効果
                  <p>・新発売かつ限定商品を宣伝でき、認知拡大・購入に繋がる</p>
                  <p>
                    ・当選者の中にはレビューを投稿してくださるユーザーもいるので、新発売商品を購入する安心材料になる
                  </p>
                </li>
              </ul>
              <h3 id="id7">5. めぐりズム公式</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <a href="https://twitter.com/hashtag/%E3%82%81%E3%81%90%E3%82%8A%E3%82%BA%E3%83%A0%E3%81%AE%E8%B6%B3%E3%81%AE%E3%82%84%E3%81%A4?src=hash&amp;ref_src=twsrc%5Etfw">
                    #めぐりズムの足のやつ
                  </a>
                  🦵
                  <br />
                  キャンペーン🎉
                  <br />
                  ＼
                  <br />
                  同じ温かい蒸気が出る商品同士、一緒にあなたの休み方に寄り添っていきたい。
                  <br />
                  <br />
                  ▼応募方法
                  <br />①
                  <a href="https://twitter.com/megrhythm_kao?ref_src=twsrc%5Etfw">
                    @megrhythm_kao
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRT
                  <br />
                  応募者の中から20名様に、めぐりズムの足のやつを【5箱セット】プレゼント🎁
                  <br />
                  <br />
                  ▼規約
                  <a href="https://t.co/wkPPf6nGbl">https://t.co/wkPPf6nGbl</a>
                  <a href="https://t.co/PzJ2hKs2HX">
                    pic.twitter.com/PzJ2hKs2HX
                  </a>
                </p>
                &mdash; めぐりズム公式 (@megrhythm_kao)
                <a href="https://twitter.com/megrhythm_kao/status/1594880847689269249?ref_src=twsrc%5Etfw">
                  November 22, 2022
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2022年11月9日〜11月25日</li>
                <li>キャンペーンタイプ：フォロー＆リツイート</li>
                <li>
                  景品：「めぐりズム じんわりスチーム 足パック 無香料 ６枚」5箱
                </li>
                <li>当選者数：20名</li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/uno_official"
                      target="_blank"
                      rel="noreferrer"
                    >
                      めぐりズムTwitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・当選者のみDM通知</p>
                </li>
                <li>
                  概要
                  <p>
                    ・「蒸気でホットアイマスク」に対抗し、2021年に販売を全国展開した足用シートが当たるキャンペーンです。
                  </p>
                </li>
                <li>
                  効果
                  <p>
                    ・実際のコメントでは「足用があることを初めて知った」というユーザーも存在し、認知度の向上となった
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h2 id="id8">
                【その場で当たる！インスタントウィン厳選】販促キャンペーン5選
              </h2>
              <h3 id="id9">1. ディアナチュラ</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <br />
                  あと3日！
                  <a href="https://twitter.com/hashtag/%E3%83%87%E3%82%A3%E3%82%A2%E3%83%8A%E3%83%81%E3%83%A5%E3%83%A9?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ディアナチュラ
                  </a>
                  がその場で当たる🎊
                  <br />
                  ＼
                  <br />
                  <br />
                  新CMの放映を記念して
                  <a href="https://twitter.com/hashtag/39%E3%82%A2%E3%83%9F%E3%83%8E%E3%81%AF%E3%81%98%E3%82%81%E3%82%88%E3%81%86?src=hash&amp;ref_src=twsrc%5Etfw">
                    #39アミノはじめよう
                  </a>
                  キャンペーン開催！
                  <br />
                  抽選で100名様にストロング39アミノ
                  マルチビタミン＆ミネラルをプレゼント🎁
                  <br />
                  <br />
                  ▼応募方法
                  <br />①
                  <a href="https://twitter.com/dearnatura_gf?ref_src=twsrc%5Etfw">
                    @dearnatura_gf
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRT♻
                  <br />
                  結果はすぐにお届け⚡
                  <br />
                  2022/4/25迄
                </p>
                &mdash; ディアナチュラ (@dearnatura_gf)
                <a href="https://twitter.com/dearnatura_gf/status/1517307195209175040?ref_src=twsrc%5Etfw">
                  April 22, 2022
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2022年4月12日〜4月25日</li>
                <li>
                  キャンペーンタイプ：インスタントウィン（フォロー＆リツイート）
                </li>
                <li>景品：「ストロング39アミノ マルチビタミン＆ミネラル」</li>
                <li>当選者数：100名</li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/dearnatura_gf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ディアナチュラTwitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・即時リプライ</p>
                </li>
                <li>
                  概要
                  <p>
                    ・新CMの放映を記念して開催されたサプリメントが当たるキャンペーンです。
                  </p>
                </li>
                <li>
                  効果
                  <p>
                    ・多数当選でユーザーの期待感を盛り上げ、参加者が集まりやすい
                  </p>
                  <p>
                    ・「サプリメント」＝「続ける必要がある」ので次回購買を促すことができる
                  </p>
                </li>
              </ul>

              <h3 id="id10">2. ミノン</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <br />
                  その場で当たる！
                  <a href="https://twitter.com/hashtag/%E3%81%86%E3%82%8B%E3%81%8A%E3%81%84%E3%81%AE%E3%81%93%E3%81%97%E6%B4%97%E3%81%84?src=hash&amp;ref_src=twsrc%5Etfw">
                    #うるおいのこし洗い
                  </a>
                  キャンペーン
                  <br />
                  【2万名様】にサンプル3点セット
                  <br />
                  プレゼント🎁
                  <br />
                  ＼
                  <br />
                  <br />
                  ▼参加は簡単💡
                  <br />①
                  <a href="https://twitter.com/minon_dshc?ref_src=twsrc%5Etfw">
                    @minon_dshc
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRTもしくは引用RT
                  <br />
                  ③結果はリプライで届く
                  <br />
                  <br />
                  敏感肌もミノンでうるおいのこし洗い✨
                  <br />
                  ▼正しい洗い方は動画で公開中です♪
                  <a href="https://t.co/Qnrd4RfUvu">
                    pic.twitter.com/Qnrd4RfUvu
                  </a>
                </p>
                &mdash; ミノン (@minon_dshc)
                <a href="https://twitter.com/minon_dshc/status/1576753907220598784?ref_src=twsrc%5Etfw">
                  October 3, 2022
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2022年10月3日〜11月11日</li>
                <li>
                  キャンペーンタイプ：インスタントウィン（フォロー＆リツイート）
                </li>
                <li>景品：ミノンサンプル3点セット</li>
                <li>当選者数：2万名</li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/minon_dshc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ミノンTwitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・即時リプライ</p>
                </li>
                <li>
                  概要
                  <p>
                    ・1ヶ月以上に渡り開催され、2万名様という大勢の参加者にサンプル3点セットが当たるキャンペーンです。
                  </p>
                </li>
                <li>
                  効果
                  <p>
                    ・多数当選でユーザーの期待感を盛り上げ、多くのユーザーに試してもらえるので、購入に繋がる可能性が高まる
                  </p>
                  <p>
                    ・当選者の中には使用感を投稿するユーザーもいるので、他Twitterユーザーに対しても販促となる
                  </p>
                </li>
              </ul>

              <h3 id="id11">3. watashi+ by shiseido</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼豪華プレゼントが毎日当たるっ❣／
                  <br />
                  　　　　 　🎄🎅⛄️
                  <br />
                  <br />
                  🎁応募は簡単🎁
                  <br />①
                  <a href="https://twitter.com/wp_shiseido?ref_src=twsrc%5Etfw">
                    @wp_shiseido
                  </a>
                  をフォロー
                  <br />
                  ②本投稿をRT
                  <br />
                  ③通知に結果が届く💕
                  <br />
                  <br />
                  コスメや手袋などが詰まった限定BOXを2人分プレゼント💝
                  <br />
                  冬のイベントは
                  <a href="https://twitter.com/hashtag/%E5%8F%8C%E5%AD%90%E3%82%B3%E3%83%BC%E3%83%87?src=hash&amp;ref_src=twsrc%5Etfw">
                    #双子コーデ
                  </a>
                  で楽しもっ👯‍♀️
                  <br />
                  <br />
                  詳細➡
                  <a href="https://t.co/HAMpCMhRE2">https://t.co/HAMpCMhRE2</a>
                  <a href="https://twitter.com/hashtag/%E3%82%AF%E3%83%AA%E3%82%B9%E3%83%9E%E3%82%B9%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9?src=hash&amp;ref_src=twsrc%5Etfw">
                    #クリスマスボックス
                  </a>
                  <a href="https://t.co/aEEPuLLbXo">
                    pic.twitter.com/aEEPuLLbXo
                  </a>
                </p>
                &mdash; watashi+ by shiseido (@wp_shiseido)
                <a href="https://twitter.com/wp_shiseido/status/1074439026243907584?ref_src=twsrc%5Etfw">
                  December 16, 2018
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2018年12月1日〜12月25日</li>
                <li>
                  キャンペーンタイプ：インスタントウィン（フォロー＆リツイート）
                </li>
                <li>
                  景品・当選者数：双子コーデアイテム 10組20名
                  <br />
                  　　　　　　　　オリジナルファンデーションブラシ 2000名
                </li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/wp_shiseido"
                      target="_blank"
                      rel="noreferrer"
                    >
                      watashi+ by shiseido Twitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・即時リプライ</p>
                </li>
                <li>
                  概要
                  <p>
                    ・クリスマスに合わせ開催され、当選者数は合計2020名様という多数当選のキャンペーンです。
                  </p>
                </li>
                <li>
                  効果
                  <p>
                    ・数種景品をプレゼントすることによって、1種よりも購買に繋がる可能性が高い
                  </p>
                  <p>
                    ・経済効果の大きなクリスマスや年末の時期に開催することで効果的に販促できる
                  </p>
                  <p>・ECサイトのコンバージョン率を向上させることができる</p>
                </li>
              </ul>

              <h3 id="id12">4. Laline〈ラリン〉公式</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <br />
                  フォロー＆RTキャンペーン💎
                  <br />＼
                  <a href="https://twitter.com/hashtag/%E3%83%A9%E3%83%AA%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ラリン
                  </a>
                  の限定セットがその場で当たる🌹
                  <br />
                  抽選であなたとその大切な人100組200名様に
                  <a href="https://twitter.com/hashtag/%E6%9C%80%E9%AB%98%E3%81%AE%E3%83%90%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%A0?src=hash&amp;ref_src=twsrc%5Etfw">
                    #最高のバスタイム
                  </a>
                  をプレゼント🎁
                  <br />
                  はずれても10%OFFクーポンGET😘
                  <br />
                  <br />①
                  <a href="https://twitter.com/LalineJAPAN?ref_src=twsrc%5Etfw">
                    @LalineJAPAN
                  </a>
                  をフォロー
                  <br />
                  ②本ツイートをRT
                  <br />
                  ③URLをタップ
                  <a href="https://t.co/Q4M5IoPhqN">https://t.co/Q4M5IoPhqN</a>
                  <a href="https://t.co/pSHoGZ4a6l">
                    pic.twitter.com/pSHoGZ4a6l
                  </a>
                </p>
                &mdash; Laline〈ラリン〉公式 (@LalineJAPAN)
                <a href="https://twitter.com/LalineJAPAN/status/1198798462395060229?ref_src=twsrc%5Etfw">
                  November 25, 2019
                </a>
              </blockquote>
              <ul>
                <li>実施期間：2019年11月25日〜12月8日</li>
                <li>キャンペーンタイプ：フォロー＆リツイート</li>
                <li>
                  景品・当選者数：クリスマス限定コレクション　100組200名
                  <br />
                  　　　　　　　　10%割引クーポン　落選者全員
                </li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/LalineJAPAN"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Laline Twitter公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・ツイート文内URLより結果確認</p>
                </li>
                <li>
                  概要
                  <p>
                    ・100組200名様にペアプレゼントが送られ、当選を共有できるキャンペーンです。
                  </p>
                  <p>
                    ・また、落選しても割引クーポンが全員にプレゼントされます。
                  </p>
                </li>
                <li>
                  効果
                  <p>・落選してもクーポンがもらえるので、参加意欲が高まる</p>
                  <p>
                    ・クーポンによって、ECサイトのコンバージョン率を向上させることができる
                  </p>
                </li>
              </ul>

              <h3 id="id13">5. 花王 エスト【公式】</h3>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼その場で1000名に当たる／
                  <br />
                  👑
                  <a href="https://twitter.com/hashtag/est%E6%B4%97%E9%A1%94%E3%83%99%E3%82%B9%E3%83%88%E3%82%B3%E3%82%B9%E3%83%A1%E5%8F%97%E8%B3%9E%E8%A8%98%E5%BF%B5W%E3%83%81%E3%83%A3%E3%83%B3%E3%82%B9CP?src=hash&amp;ref_src=twsrc%5Etfw">
                    #est洗顔ベストコスメ受賞記念WチャンスCP
                  </a>
                  🎉
                  <br />
                  <br />
                  【参加方法】
                  <br />①
                  <a href="https://twitter.com/est_kao_jp?ref_src=twsrc%5Etfw">
                    @est_kao_jp
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRT
                  <br />
                  ③リプライで当落結果通知
                  <br />
                  <br />
                  【応募期間】
                  <br />
                  2022/7/20(水)23:59まで
                  <a href="https://twitter.com/hashtag/%E3%82%A8%E3%82%B9%E3%83%88%E3%82%AF%E3%83%A9%E3%83%AA%E3%83%95%E3%82%A1%E3%82%A4%E3%82%A4%E3%83%B3%E3%82%B0%E3%82%B8%E3%82%A7%E3%83%AB%E3%82%A6%E3%82%A9%E3%83%83%E3%82%B7%E3%83%A5?src=hash&amp;ref_src=twsrc%5Etfw">
                    #エストクラリファイイングジェルウォッシュ
                  </a>
                  <br />
                  <br />
                  エスト ザ ローションが当たるWチャンスCPも実施！
                </p>
                &mdash; 花王 エスト【公式】 (@est_kao_jp)
                <a href="https://twitter.com/est_kao_jp/status/1541987320575848448?ref_src=twsrc%5Etfw">
                  June 29, 2022
                </a>
              </blockquote>
              <ul>
                <li>
                  実施期間：2022年6月29日〜7月20日（Wチャンス：2022年7月6日〜8月4日）
                </li>
                <li>キャンペーンタイプ：フォロー＆リツイート</li>
                <li>
                  景品・当選者数：エストクラリファイイングジェルウォッシュ
                  1000名
                  <br />
                  　　　　　　　　Wチャンスでエストザローション 50名
                </li>
                <li>
                  参加方法
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/est_kao_jp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      花王 エスト公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                </li>
                <li>
                  結果通知
                  <p>・即時リプライ</p>
                </li>
                <li>
                  概要
                  <p>
                    ・洗顔料が大勢に当たり、当選者が期間中に「
                    <a
                      href="https://twitter.com/hashtag/%E5%A4%8F%E3%81%AE%E9%9A%A0%E3%82%8C%E8%A7%92%E6%A0%93%E3%82%B1%E3%82%A2?src=hashtag_click"
                      target="_blank"
                      rel="noreferrer"
                    >
                      #夏の隠れ角栓ケア
                    </a>
                    」を付けて洗顔の画像と感想を投稿するとさらに抽選でエスト ザ
                    ローションが当たるWチャンスキャンペーンです。
                  </p>
                </li>
                <li>
                  効果
                  <p>・ECサイトのコンバージョン率を向上させることができる</p>
                  <p>・多数当選でユーザーの期待感や参加意欲を盛り上げる</p>
                  <p>
                    ・多数当選に加え、感想を投稿してもらうWチャンスキャンペーンによってより多くのユーザーに訴求できる
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h2 id="id14">
                【コスメ・ヘルスケア向け】おすすめの販売促進キャンペーンアイデアとは
              </h2>
              <p>
                コスメ・ヘルスケアのアカウントでTwitterキャンペーンを開催する際、効率よく商品を訴求するための方法をご紹介します。
              </p>
              <h3>キャンペーン形式</h3>
              <ul>
                <li>
                  フォロー＆リツートキャンペーンでフォロワー獲得
                  <p>
                    Twitterキャンペーンでは最もメジャーな方法です。簡単に応募でき、参加者が集まりやすいので拡散力が高いことが特徴です。
                  </p>
                  <p>
                    Twitterキャンペーンを初めて実施する場合はフォロー＆リツートキャンペーンでフォロワーを獲得し、他キャンペーンを開催することをおすすめします。また、継続的にキャンペーンを開催することで既存フォロワーにフォローを解除されにくくなります。
                    <br />
                    <strong>
                      特に、「その場で当たる」インスタントウィンタイプのフォロー＆リツイートキャンペーンは、多くの参加が見込め、ツイートも拡散しやすくなります。
                    </strong>
                  </p>
                  <div className="blog__border-highlight">
                    <p>あわせて読みたい</p>
                    <p>
                      ▶︎
                      <a
                        href="/blog/comparison-of-instant-win-and-later-lottery"
                        target="_blank"
                        rel="noreferrer"
                      >
                        インスタントウィンキャンペーン（即時抽選）と手動キャンペーン（後日抽選）どちらがおすすめ？インスタントウィンの仕組みとメリット・デメリットを解説します
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  クーポン配布キャンペーン
                  <p>
                    おすすめは参加者全員にクーポンが配布されるキャンペーン、または落選者に割引クーポンが当たるキャンペーンです。「必ず」景品があるキャンペーンはユーザーの参加意欲を向上させます。そして、クーポンを配布することで実店舗やECサイトの訪問率増加や購入に繋がります。
                  </p>
                  <div className="blog__border-highlight">
                    <p>あわせて読みたい</p>
                    <p>
                      ▶︎
                      <a
                        href="/blog/coupon-prize"
                        target="_blank"
                        rel="noreferrer"
                      >
                        TwitterインスタントウィンのNGプレゼントは？
                        賞品プレゼントとクーポン配布はどっちがいいの？【メリット・デメリット比較】
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  UGC活用
                  <p>
                    ECサイトをお持ちであればUGCを活用されてはいかがでしょうか。
                  </p>
                  <p>
                    口コミやレビューなど実際の声を参考にできるので、ユーザーの信頼を獲得しコンバージョン率を向上させることができます。また、高額なほど購入の障壁も高くなるので、ユーザーの声は重要な検討材料となります。
                  </p>
                  <div className="blog__border-highlight">
                    <p>あわせて読みたい</p>
                    <p>
                      ▶︎
                      <a
                        href="/blog/what-is-ugc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        UGCとは？活用のメリットや上手な活用方法・注意点をわかりやすく解説！
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
              <h3>キャンペーン内容</h3>
              <ul>
                <li>
                  景品：試供品
                  <p>
                    「9割の消費者が化粧品を買うときは、サンプルやテスターを試してから購入したいと回答した」というデータがあります。※3
                    特に価格が高い商品は購入のハードルも高いので、実際に使い心地の良さを実感できることは購入の決定打となり得るので、景品は試供品がおすすめです。
                  </p>
                  <p>
                    「届いてからのお楽しみ」という景品でもユーザーのワクワク感を与えることができますが、初めてのキャンペーンには不向きかもしれません。
                  </p>
                  <p>
                    ※3 参考：
                    <a
                      href="https://www.istyle.co.jp/news/info/2021/10/1014.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      9割が化粧品を買うときは、サンプルやテスターを試してから購入したいと回答
                      二次流通でのお試し買いは一次流通での商品購入の鍵を握る？
                      「@cosmeにおける二次流通での化粧品購入実態調査」結果
                      [istyle 株式会社アイスタイル]
                    </a>
                  </p>
                </li>
                <li>
                  落選者への景品：割引クーポン
                  <p>
                    景品が外れても、実店舗やECサイトで使えるクーポンがもらえると来店やECサイトの訪問率、購入率の向上に繋がります。全員プレゼントであればよりキャンペーン参加のモチベを上げ、拡散されやすくなります。
                  </p>
                </li>
                <li>
                  バナー画像：景品の写真を用いて、ブランドのイメージに合わせつつ内容がわかりやすい清潔感のあるデザインにする
                  <p>
                    コスメやヘルスケア商品は体調に関わるのものなので、特に清潔なイメージが重要です。また、画像は文字よりも情報を効率よく伝達できるので、まとまりのある一目で分かりやすいデザインにしましょう。ブランドのイメージに合わせた色や素材、商品の写真を用いると印象を残しやすくなります。
                  </p>
                </li>
              </ul>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/how-to-instant-win-campaign-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1
                  </a>
                </p>
                <p>
                  ▶︎
                  <a
                    href="/blog/how-to-instant-win-campaign-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.2
                  </a>
                </p>
              </div>
              <h3>キャンペーン方法</h3>
              <ul>
                <li>
                  商品の新発売やリニューアルの告知や宣伝として行う
                  <p>
                    新店舗やECサイト開設などの記念キャンペーンもおすすめです。
                  </p>
                </li>
                <li>
                  季節のイベントに合わせ行う
                  <p>
                    お正月、サマーセール、ハロウィン、クリスマスなど季節のイベントに合わせることで拡散力が上がります。
                  </p>
                </li>
                <li>
                  来店やECサイトのコンバージョン率を上げるのであればクーポンを配布する
                  <p>
                    クーポンは来店のきっかけや特に高額商品であれば、購入の後押しとなります。
                  </p>
                </li>
                <li>
                  Wチャンスキャンペーンや指定ハッシュタグ投稿、引用リツイートによって、更なる拡散を狙う
                  <p>
                    感想を特定ハッシュタグでツイートし、その中からさらに抽選で何か当たるWチャンスキャンペーンにすれば、他ユーザーへの訴求になります。
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h2 id="id15">
                SNSをうまく活用してコスメ・ヘルスケアの売上向上へつなげよう
              </h2>
              <p>
                今回は、コスメ・ヘルスケア商品に特化した販促キャンペーンを紹介しました。自社でも参考にしたい内容がたくさんあったかと思います。
                <br />
                SNSでキャンペーンをするメリットとして、さまざまな企画内容を試せるという点も挙げられます。何度か販促キャンペーンを行なってみて、自社製品の対象ユーザーが喜ぶ内容へとブラッシュアップしてみてください。
              </p>
              <p>
                改善点がわからないのでアドバイスが欲しい、キャンペーンをカスタマイズしてブランドイメージを保ちつつ認知を拡大したい、などのご要望は、PARKLoTへお気軽にご相談ください。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="kakefuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-of-instant-win-and-later-lottery"
                    target="_blank"
                    rel="noreferrer"
                  >
                    インスタントウィンキャンペーン（即時抽選）と手動キャンペーン（後日抽選）どちらがおすすめ？インスタントウィンの仕組みとメリット・デメリットを解説します
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/tips-for-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【SNSキャンペーン】本気で販促につなげるなら！すぐできる10のポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-digital-lottery"
                    target="_blank"
                    rel="noreferrer"
                  >
                    来店集客イベントに！デジタル抽選会の導入例＆注意点を解説します【コスト削減＆感染症対策】
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                    6選
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施する際に生じるリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-get-friends-for-line"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LINE公式アカウントの友だちを増やす方法はこれ！「Twitter×LINEハイブリッドキャンペーン」
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default ExamplesOfCosmeticsAndHealthcare
